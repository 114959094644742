import { ISprinklersDevice, IUser } from "@common/httpApi";
import { action, IObservableValue, observable } from "mobx";

export class UserStore {
  userData: IObservableValue<IUser | null> = observable.box(null);

  @action.bound
  receiveUserData(userData: IUser) {
    this.userData.set(userData);
  }

  getUserData(): IUser | null {
    return this.userData.get();
  }

  findDevice(id: number): ISprinklersDevice | null {
    const userData = this.userData.get();
    return (
      (userData &&
        userData.devices &&
        userData.devices.find(dev => dev.id === id)) ||
      null
    );
  }
}
